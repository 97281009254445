import React, { useState, useEffect } from 'react';
import './slider.css'
import image4 from "../../assets/img17-1.jpg"
import image5 from "../../assets/14.jpg"
import image16 from "../../assets/img16.jpg"
import image8 from "../../assets/img8.jpg"
import image17 from "../../assets/img17.jpg"

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
      image4,
      image5,
      image16,
      image8,
      image17,
  ];


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 8000);
    return () => clearInterval(intervalId);
  }, [currentIndex]); 

  return (
    <>
    <div className='slideshow'>
        <div className="Slider">
          <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
        </div>
        <div className='slideText'>
            <h3>"Journey Through Our Lens"</h3>
              <div className='text'>
              "Embark on a visual journey with TrueColors Photography, where every click tells a unique story. Our lens captures the essence of fleeting moments, turning them into timeless memories. From the enchanting allure of weddings to the vibrant energy of special events, our passion for photography is reflected in every shot. Immerse yourself in a world of emotions and beauty as we bring your cherished moments to life through the art of storytelling. TrueColors Photography is dedicated to crafting images that resonate, making each photograph a testament to the genuine joy and elegance that define your special occasions."
              </div>
        </div>
    </div>
    </>
  );
};

export default Slider;

