import React from "react";
import Nav from "./components/navbar/Nav";
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import ScrollButton from "./components/ScrollButton";



function App() {
  return (
      <>
      <BrowserRouter>
        <Nav/>
        <div>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/About" element={<About/>}/>
            <Route path="/Gallery" element={<Gallery/>}/>
            <Route path="/Contact" element={<Contact/>}/> 
          </Routes>
        </div>
        <ScrollButton />
      </BrowserRouter>
      </>
    );
}

export default App;
