import React from 'react';
import { useNavigate } from 'react-router-dom';
import TypeAnimations from '../components/typeanimation/TypeAnimations';
import styled from 'styled-components';
import Card from '../components/servicecards/Card'
import photo1 from '../assets/06.jpg'
import photo2 from '../assets/img16.jpg'
import photo5 from '../assets/birthday.jpg'
import photo3 from '../assets/16.JPG'
import photo4 from '../assets/event.jpg'
import AboutContent from '../components/AboutContent';


const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: 1fr 1fr; 
  gap: 0px 0px; 
  justify-content: center;
  margin-left: 12px;
  padding:10px;

 
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 601px) and (max-width: 900px) {
   grid-template-columns: repeat(2, 1fr);
}

    @media (min-width: 901px) and (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1201px) {
      grid-template-columns: repeat(4, 1fr / 12); 
    }
  
`;

const AboutContainer = styled.div`

`;

const AboutUs = styled.div`
    position: absolute;
    top:13%
`;
export default function About() {

    const navigate = useNavigate();
    const handleClick = () => {
      // Use navigate to navigate to the "About Us" page
      navigate('/gallery');
    };
  
  return (
    <>
    <AboutUs>

      <AboutContainer>
            <TypeAnimations/>
            <AboutContent/>  
      </AboutContainer>

      <CardContainer>
      <Card imageUrl={photo1} title="Wedding Ceremony" onClick={handleClick}/>
      <Card imageUrl={photo2} title="Outdoor Photoshoot"  onClick={handleClick}/>
      <Card imageUrl={photo4} title="Event Coverage" onClick={handleClick}/>
      <Card imageUrl={photo5} title="Birthday Photography" onClick={handleClick}/>
      <Card imageUrl={photo3} title="Kids Photography" onClick={handleClick}/>
      </CardContainer>
    </AboutUs>

    </>
  )
}
