import React from 'react';
import styled from 'styled-components';
import '../index.css'

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Caption = styled.h3`
    color: black;
    font-weight: lighter;
    text-align: center;
    font-size: 1.8rem;
    @media(max-width:560px){
      margin: 10px
    }
`;

const Para = styled.h3`
    color: black;
    opacity: 0.8;
    font-weight: lighter;
    text-align: center;
    padding-top: 15px;
    position: relative;
    width: 90%;
    @media (max-width: 768px) {
        z-index: -4;
        // font-size: 1.3rem;
        font-weight: 500;
      }
    @media (min-width: 1200px) {
        font-size: 1.5rem;
        line-height:1.5;
    }
    @media (min-width: 700px) and (max-width: 1200px) {
      font-size: 1.5rem;
      line-height:1.5;
  }
`;

const Heading = styled.h2`
color: black;
opacity:0.9;
font-size:2.8rem;
font-weight:600;

text-align: center;
// left: 27px;
position: relative;
width: 90%;

@media (max-width: 768px) {
  left:0px
}

`

function AboutContent() {
  return (
    <>
      <TextBlock>
      <Caption> "Life is a kaleidoscope through our lens."</Caption>
      <Para>Welcome to TrueColors Photography, beyond black and white, we capture the true hues of life.We are more than just photographers; we are storytellers, capturing the essence of your most cherished moments with authenticity and artistry. At TrueColors, we have a straightforward vision: using the vibrant palette of true emotions to paint your memories. Our team is dedicated to bring out the true colors of every moment, making each image a masterpiece in its own right.
      Whether it is a wedding, a family portrait, or a milestone event, we are here to tell your story. Let TrueColors Photography be the brush that paints your memories vividly and authentically.
      </Para>
      {/* <Para>At TrueColors, we have a straightforward vision: using the vibrant palette of true emotions to paint your memories. Our team is dedicated to bring out the true colors of every moment, making each image a masterpiece in its own right.</Para>
      <Para>Whether it is a wedding, a family portrait, or a milestone event, we are here to tell your story. Let TrueColors Photography be the brush that paints your memories vividly and authentically.</Para> */}
      <Para>Thank you for considering us to be a part of your journey.</Para>
  
      <Heading>OUR SERVICES</Heading><br />
      </TextBlock>

    </>
  );
}

export default AboutContent;
