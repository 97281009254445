import React from 'react'
import WeddingPhotos from '../components/weddingphotos/WeddingPhotos'

export default function Gallery() {
  return (
    <>
    <br/>
    <WeddingPhotos/>
      {/* <OutdoorPhotos/> */}
    </>
  )
}

