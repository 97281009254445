// import React from 'react';
// import './template.css';
// import camera from '../../assets/cam4.webp'

// function Template() {
//   return (
//     <>
//         <div class="template">
//         <div class="about-content">
//           <div class="overlay">
//             {/* <img src={camera} alt="camera" className='camera'/> */}
//             <h2 class="main-title" id="main-title">Truecolors photography</h2>
//             <h3>Wedding | Events</h3>
//             <blockquote class="overflow">
//               'The most important thing at a wedding is to photograph the bride.
//               The second most important thing is not to forget the groom.'
//             </blockquote>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// export default Template



import React from 'react';
import './template.css'

const Template = () => {
  return (
    <>
      <div class="main">
     <div class="slider" id="slider">
      <div class="slides current">
            <h2 class="main-title" id="main-title">Truecolors photography</h2>
             <h3 className='category'>Wedding | Events</h3>
             <blockquote class="overflow">
               'The most important thing at a wedding is to photograph the bride.
               The second most important thing is not to forget the groom.'
             </blockquote>
       </div>
    </div>
    </div>
    </>
  )
}

export default Template
