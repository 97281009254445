import React from 'react';
import './footer.css';
import { FaMapMarkerAlt, FaPhone, FaEnvelopeOpen } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-container">
        <div className="footer-cta ">
          <div className="row">

              <a href="https://www.google.com/maps/place/Sainikpuri,Hyderabad" target="_blank" rel="noopener noreferrer" className="single-cta">
                <FaMapMarkerAlt className="location"></FaMapMarkerAlt>
                <div className="cta-text">
                  <h4>Find us</h4>
                  <span >Sainikpuri, Hyderabad</span>
                </div>
              </a>

              <a href="tel:7901024455" className="single-cta">
                <FaPhone className="phone"></FaPhone>
                <div className="cta-text">
                  <h4>Call us</h4>
                  <span>7901024455</span>
                </div>
              </a>

              <a href="mailto:truecolors143@gmail.com" className="single-cta">             
                <FaEnvelopeOpen className="mail"></FaEnvelopeOpen>
                <div className="cta-text">
                  <h4>Mail us</h4>
                  <span>truecolors143@gmail.com</span>
                </div>
              </a>
            </div>
          </div>
        </div>

        
    </footer>
  );
};

export default Footer;
