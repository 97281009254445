import React from "react";
import { useNavigate } from "react-router-dom";
import "../aboutHome/AboutHome.css";
import ReactPlayer from "react-player";
import video from "../../assets/wedding/wedding-promo.mp4";




const AboutHome = () => {
  const navigate = useNavigate();

  const handleKnowMoreClick = () => {
    navigate("/about");
  };
  const handleContactClick = () => {
    navigate("/contact");
  };
  return (
    <>
      <div className="aboutHome">
        <div className="aboutVideo">
          <ReactPlayer
            url={video}
            controls
            playing={false}
            volume={1}
            width="90%"
            height="75%"
          />
        </div>
        <div className="flexbox">
          <h2>Why <br></br>Truecolors <br></br>Photography?</h2> 
          <div className="text-section">
            <p>
              Welcome to TrueColors Photography, where moments come to life
              through the lens of creativity! We are a passionate team dedicated
              to capturing the essence of your most cherished events. With a
              keen eye for detail and a commitment to storytelling, we
              specialize in crafting timeless memories for weddings and events.
              Our mission is to make every photograph a work of art, reflecting
              the genuine emotions and beauty of your special moments.
            </p>
            <div className="buttons">
              <button id="btn1" className="btn-2" onClick={handleKnowMoreClick}>
                {" "}
                Know More
              </button>
              <button id="btn2" onClick={handleContactClick}>
                Contact Us
              </button>
            </div>
          </div>
        </div>
        {/* <img src={outdoor} alt="outdoor" className='aboutImage'/> */}
        {/* <video autoPlay loop muted className='aboutVideo'>
              <source src={video} type='video/mp4' />
              Your browser does not support the video tag.
          </video> */}
      </div>
    </>
  );
};

export default AboutHome;
