import React, {useState} from 'react'; 
import {FaChevronCircleUp  } from 'react-icons/fa'; 
import styled from 'styled-components';

export const Button = styled.div` 
   position: fixed;  
   width: 100%; 
   left: 92%; 
   bottom: 60px; 
   height: 20px; 
   font-size: 2.8rem; 
   width: 100%;
   z-index: 1; 
   cursor: pointer; 
   color: #ff5e14; 

   @media (max-width: 600px) {
	left: 85%;
   }
`

const ScrollButton = () =>{ 
const [visible, setVisible] = useState(false) 

const toggleVisible = () => { 
	const scrolled = document.documentElement.scrollTop; 
	if (scrolled > 300){ 
	setVisible(true) 
	} 
	else if (scrolled <= 300){ 
	setVisible(false) 
	} 
}; 

const scrollToTop = () =>{ 
	window.scrollTo({ 
	top: 0, 
	behavior: 'smooth'
	/* you can also use 'auto' behaviour 
		in place of 'smooth' */
	}); 
}; 

window.addEventListener('scroll', toggleVisible); 

return ( 
	<Button> 
	<FaChevronCircleUp   onClick={scrollToTop} 
	style={{display: visible ? 'inline' : 'none'}} /> 
	</Button> 
); 
} 

export default ScrollButton; 
